import { createRouter, createWebHistory } from 'vue-router'
import Home            from '@/views/Home'
import Register        from '@/views/Register'
import Login           from '@/views/Login'
import outils          from '@/views/Outils'
import MentionLegales  from '@/views/MentionLegales'
import Apropos         from '@/views/Apropos'
import contact         from '@/views/contact'
import apiEndpoint     from '@/plugins/axios'
import DashboardClient from '@/views/DashboardClient'
import DashboardAdmin from '@/views/DashboardAdmin'
import ClientAccount from '@/views/ClientAccount'
import PlayerBar from '@/components/PlayerBar.vue'

const router = createRouter({
    // history: createWebHistory(),
    history: createWebHistory(process.env.BASE_URL), 
    
    
    routes: [
        {
            name: "Home",
            path: '/',
            components: {
                default: Home,
                PlayerBar
            },
            meta: {
                needAuth: false
            }
        },
        {
            name: "Register",
            path: '/register',
            components: {
                default: Register,
                PlayerBar
            },
            meta: {
                needAuth: false
            }
        },
        {
            name: "Login",
            path: '/login',
            components: {
                default: Login,
                PlayerBar
            },            
            meta: {
                needAuth: false
            }
        },
        {
            name: "Outils",
            path: '/outils',
            components: {
                default: outils,
                PlayerBar
            },            
            meta: {
                needAuth: false
            }
        },
        {
            name: "MentionLegales",
            path: '/mentionslegales',
            components: {
                default: MentionLegales,
                PlayerBar
            },           
            meta: {
                needAuth: false
            }
        },
        {
            name: "Apropos",
            path: '/Apropos',
            components: {
                default: Apropos,
                PlayerBar
            },
            meta: {
                needAuth: false
            }
        },
        {
            name: "Contact",
            path: '/contact',
            components: {
                default: contact,
                PlayerBar
            },
            meta: {
                needAuth: false
            }
        },
        {
            name: "DashboardClient",
            path: '/DashboardClient',
            components: {
                default: DashboardClient,
                PlayerBar
            },            
            meta: {
                needAuth: true
            }
        },
        {
            name: "DashboardAdmin",
            path: '/DashboardAdmin',
            components: {
                default: DashboardAdmin,
                PlayerBar
            },            
            meta: {
                needAuth: true,
                needAdmin: true
            }
        },
        {
            name: "ClientAccount",
            path: '/ClientAccount',
            components: {
                default: ClientAccount,
                PlayerBar
            },            
            meta: {
                needAuth: true
            }
        },

    ],
    scrollBehavior(/*to, from, savedPosition*/) {
        // always scroll to top
        return { top: 0 }
    },

      
})

router.beforeEach((to, from) => {
    apiEndpoint.get('/user/me').then((response) => {
        window.localStorage.setItem('user', JSON.stringify(response.data));

        
    }).catch((e) => {
        if(to.meta.needAuth) {
            router.push('/login')
        }
        
    });
    //for admin
    // if(){

    // }
    // apiEndpoint.get('/user/me').then((response) => {
    //     window.localStorage.setItem('user', JSON.stringify(response.data));

    //     //recupere la list des clients
    //     // apiEndpoint.get('user/customersList').then((response)=>{
    //     //     window.localStorage.setItem('custommesList', JSON.stringify(response.data));
    //     // })
    // }).catch((e) => {
    //     if(to.meta.needAdmin) {
    //         router.push('/login')
    //     }
        
    // });
})

// apiEndpoint.put('/user/' + user.id, {
//     name: 'toto',
//     email: 'shemale',
//     password: 'tonpere'
// });


export default router;

