<template>
  <div class="HomePage-Container">
    <div class="MusicContainer">
      <div class="TopViewer">
        <div class="filter">
        <h1>Qui sommes nous?</h1>
        <div class="presentation">
          Situé à Valence (26), DEON Studio vous accueille dans son studio d'enregistrement pour vos projets de l’industrie musicale.
        </div>
        <form>
          <input
            class="search-music"
            type="text"
            name="search"
            placeholder="Explorer nos musiques / instrumentales ... "
          />
        </form>
        </div>
      </div>
      <div class="BotViewer">
        <h1>Dernière pistes</h1>
        <div class="VideoContainer">
          <div
            class="music-jacket"
            v-for="image in sortedImages"
            :key="image.name"
          >
            <img class="Thumbnail" :src="image.src" :alt="image.name" />
            <img class="play-button" :src="playButton" alt="Play" @click="$emit('play-music')" />
          </div>
        </div>
        <!-- <div id="videoElement">
                </div> -->
      </div>
      <div class="BotViewer">
        <h1>Nos services</h1>
        
        
        <div class="mix-service">
          <div class="description">
            <h2>Mix</h2>
            <p>
              L'objectif ultime est de donner vie à votre vision de l'enregistrement, grâce à notre expérience dans la production musicale. Nous vous guiderons tout au long de ce processus...
            </p>
          </div>
          <div class="faders-container">
            <div class="fader">
              <div class="but-fader1" />
            </div>
            <div class="fader">
              <div class="but-fader2" />
            </div>
            <div class="fader">
              <div class="but-fader3" />
            </div>
          </div>
        </div>
        <div class="mastering-service">
          <div class="Limiter-container">
            <div class="limiter">
              <div class="index-limiter">0 dB</div>
              <div class="num-limiter">-40 dB</div>

              <div class="niv-limiter"></div>
            </div>
          </div>
          <div class="description">
            <h2>mastering</h2>
          <p>Vous voulez que votre mix sonne parfaitement sur n'importe quel support ?</p>
          </div>
        </div>
        <div class="record-service">
          <div class="description">
          
          <h2>enregistrement</h2>
          <p>Prise de voix, d'instruments ou autres... On vous accompagne</p>
        
          </div>
          <div class="rec-container">
          <div class="bg-waves">
        </div>
        </div>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
import playButton from "@/assets/IMGs/play-button.png";



export default {
  data() {
    return {
      imageNames: ["1.jpg", "2.jpg", "3.jpg", "4.jpg"], // Ajoutez d'autres images si nécessaire
      sortedImages: [],
      playButton: playButton,
    };
  },
  methods: {
    sortImagesByNumber() {
      this.sortedImages = this.imageNames
        .map((name) => ({
          name,
          src: `Thumbnails/${name}`,
          number: parseInt(name.match(/\d+/)[0], 10),
        }))
        .sort((a, b) => b.number - a.number)
        .slice(0, 4);
    },
    myMethod() {
      let fadersBlock = document.querySelector(".faders-container");
      let fader1 = document.querySelector(".but-fader1");
      let fader2 = document.querySelector(".but-fader2");
      let fader3 = document.querySelector(".but-fader3");
      let nivLimiter = document.querySelector(".niv-limiter");
      let niveau = document.querySelector(".num-limiter");

      function getRandomInt(max) {
        return Math.floor(Math.random() * max);
      }

      window.addEventListener("scroll", () => {
        let scrollTop = window.scrollY;
        let docHeight = document.body.offsetHeight;
        let winHeight = window.innerHeight;
        let scrollPercent = scrollTop / (docHeight);
        // let nivlim = niveau.innerHTML;

        fader1.style.transform =`translateY(` +(fadersBlock.clientHeight * getRandomInt(200)) / 1000 +`%)`;
        fader2.style.transform =`translateY(` +(fadersBlock.clientHeight * getRandomInt(200)) / 1000 +`%)`;
        fader3.style.transform =`translateY(` +(fadersBlock.clientHeight * getRandomInt(200)) / 1000 +`%)`;
        nivLimiter.style.height = scrollPercent * 100+50 + "%";
        
        if (scrollPercent * 100+50 >= 80) {
          nivLimiter.style.background =
            "linear-gradient(180deg,#ff0000,#ffc400, 40%,#25b94a)";
          niveau.innerHTML =
            "+" +
            Math.round((scrollTop / (docHeight - winHeight)) * 100 - 30) +
            "dB";
        } else {
          nivLimiter.style.background =
            "linear-gradient(180deg,#ffc400, #25b94a)";
          niveau.innerHTML =
            Math.round((scrollTop / (docHeight - winHeight)) * 100 - 30) + "dB";
        }

        if (scrollPercent * 100+50 >= 100) {
          nivLimiter.style.height = 100 + "%";
          niveau.innerHTML =
            "+" +
            Math.round((scrollTop / (docHeight - winHeight)) * 100 - 30) +
            "dB";
        }
      });
    },
  },
  mounted() {
    this.sortImagesByNumber();
    this.myMethod();
  },
};
</script>

<style scoped>
@media only screen and (max-width: 800px) {
  /* FOR MOBILE*/
  input[type="text"] {
    text-align: center;
    background-color: white;
    background-image: url("@/assets/SVGs/search.svg");
    background-position: 1% 50%;
    background-size: 4%;
    background-repeat: no-repeat;
    border: none;

    height: 4vh;
    width: 80%;
    border-radius: 2rem;
    font-size: 3vw;
  } 
  .filter{
    width: 100%;
    height: 100%;
    padding-top: 10%;
    padding-bottom: 10%;
    margin: auto;

    text-align: center;
  }
  h1{
    font-size: 15px;
  }
  .TopViewer {
    width: 100%;
    height: 100%;
    
  }
  .presentation {
    font-size: 10px;
    align-content: center;
    width: 100%;
    font-family: Font2;
    color: var(--Text-color);
    padding-top: 1%;
    padding-bottom: 2%;
  }
  .mix-service {
    height: 20vh;
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
  }
  .faders-container {
  /* transform: perspective(400px) rotateY(-15deg); */
  height: 20vh;
  width: 50%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  align-content: stretch;
}
.mastering-service{
  width: 100%;
  height: 20vh;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
}
.num-limiter{
  position: absolute;
  margin: auto;
  top: -7%;
  
  height: 100%;
  width: 30vw;
  text-align: center;
  font-size: 1.5vw;
  color: var(--Text-color);
  font-family: Font1;
}
.limiter{
  height: 20vh;
  width: 5%;
  position: relative;
  border: 2px solid #292929;
  background: var(--box-color);
  border-radius: 20rem;
  box-shadow: inset 20px 20px 18px #0d0d0d, inset -20px -20px 18px #171717;
  display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: flex-end;
	align-content: stretch;
}

.limiter::before {
  content: "";
  position: absolute;
  background-color: white;
  width: 50%;
  height: 2px;
  left: -60%;
  top: 20%;
}

.limiter::after {
  content: "";
  position: absolute;
  background-color: white;
  width: 50%;
  height: 2px;
  top: 20%;
  right: -60%;
}
.Limiter-container{
  height: 20vh;
  width: 50%;
  margin: auto;
  display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: center;
}
.record-service{
  width: 100%;
  height: 20vh;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
}
h2{
  font-size: 15px;
}
 .bg-waves{
  
  background-image: url("../assets/SVGs/Bouche.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 80%;
  height: 20vh;
  
  
} 
.rec-container{
  height: 20vh;
  width: 50vw;
  display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: center;
  text-align: center;

  animation: center-animate .3s ease infinite alternate;
  
}
.but-fader1,
.but-fader2,
.but-fader3 {
  position: relative;
  height: 20%;
  width: 80%;
  right: 1px;

  border: 2px solid #535353;
  border-radius: 20rem;

  background: #535353;
  box-shadow: inset 5px 5px 2px #3a3a3a, inset -5px -5px 2px #6c6c6c;
  transform-origin: top;
  transform: translateY(0%);
  transition: 2s all ease;
}
}
@media only screen and (min-width: 800px) {
  /* for desktop*/
  .search-music {
    text-align: center;
    background-color: white;
    background-image: url("@/assets/SVGs/search.svg");
    background-position: 1% 50%;
    background-size: 4%;
    background-repeat: no-repeat;
    border: none;
    /* padding-left: 8%; */
    height: 5vh;
    width: 40vw;
    border-radius: 2% 2% 2% 2% / 30% 30% 30% 30%;
    font-size: 1vw;
  }
  .filter{
    width: 98vw;
    height: 100%;
    padding-top: 10%;
    padding-bottom: 10%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    z-index: 1 !important;
    background: rgb(255,255,255);
    background: -moz-radial-gradient(circle, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 100%);
    background: -webkit-radial-gradient(circle, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 100%);
    background: radial-gradient(circle, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#000000",GradientType=1);
  }
  .TopViewer {
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("@/assets/IMGs/background.jpg");
    width: 98vw;
    height: 100%;
    
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    z-index: 1 !important;
    border-radius: 10px;
    margin-bottom: 1rem;
  }
  .presentation {
    text-align: center;
    width: 40%;
    font-size: 0.8vw;
    font-family: Font1;
    color: var(--Text-color);
    padding-top: 1%;
    padding-bottom: 2%;
  }
  .mix-service {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
}
.faders-container {
  /* transform: perspective(400px) rotateY(-15deg); */
  height: 50vh;
  width: 50%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  align-content: stretch;
}
.mastering-service{
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
}
.num-limiter{
  position: absolute;
  margin: auto;
  top: -7%;
  
  height: 100%;
  width: 30vw;
  text-align: center;
  font-size: 1.5vw;
  color: var(--Text-color);
  font-family: Font1;
}
.limiter{
  height: 90%;
  width: 5%;
  position: relative;
  border: 2px solid #292929;
  background: var(--box-color);
  border-radius: 20rem;
  box-shadow: inset 20px 20px 18px #0d0d0d, inset -20px -20px 18px #171717;
  display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: flex-end;
	align-content: stretch;
}

.limiter::before {
  content: "";
  position: absolute;
  background-color: white;
  width: 50%;
  height: 2px;
  left: -60%;
  top: 20%;
}

.limiter::after {
  content: "";
  position: absolute;
  background-color: white;
  width: 50%;
  height: 2px;
  top: 20%;
  right: -60%;
}
.Limiter-container{
  height: 50vh;
  width: 50%;
  margin: auto;
  display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: center;
}
.record-service{
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
}
h2{
  font-size: 2rem;
}
.bg-waves{
  
  background-image: url("../assets/SVGs/Bouche.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 80%;
  height: 80%;
  
  
}
.rec-container{
  height: 50vh;
  width: 50vw;
  display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: center;
  text-align: center;

  animation: center-animate .3s ease infinite alternate;
  
}
.but-fader1,
.but-fader2,
.but-fader3 {
  position: relative;
  height: 20%;
  width: 92%;

  border: 2px solid #535353;
  border-radius: 20rem;

  background: #535353;
  box-shadow: inset 5px 5px 2px #3a3a3a, inset -5px -5px 2px #6c6c6c;
  transform-origin: top;
  transform: translateY(0%);
  transition: 2s all ease;
}
}





.niv-limiter{
  position: relative;
  height: 50%;
  width: 100%;  
  border-radius: 20rem;
  background: linear-gradient(180deg, #ffc400, #25b94a);
  
}




.index-limiter {
  position: absolute;
  top: 17.5%;
  /* left: 0; */
  width: 100%;
  margin: auto;
  text-align: center;
  font-size: 1.3vw;
  color: var(--Text-color);
  font-family: Font2;
}




.description p {
  text-align: center;
  font-family: Font2;
  width: 50%;
}
.description {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}


.fader {
  height: 100%;
  width: 1.5vw;
  border: 2px solid #292929;
  background: var(--box-color);
  margin-left: 5%;
  margin-right: 5%;
  box-shadow: inset 20px 20px 18px #0d0d0d, inset -20px -20px 18px #171717;

  border-radius: 20rem;

  align-items: center;
  align-content: center;
}




.music-jacket:hover .Thumbnail {
  cursor: pointer;
  background: black;
  opacity: 1;
  transition: opacity 0.25s ease-in-out;
  -moz-transition: opacity 0.25s ease-in-out;
  -webkit-transition: opacity 0.25s ease-in-out;
}

.music-jacket {
  position: relative;
}

.play-button {
  display: none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.music-jacket:hover .play-button {
  display: block;
}

.Thumbnail {
  width: 15vw;
  border-radius: .5rem;
  align-content: center;
  opacity: 0.7;
  transition: opacity 0.25s ease-in-out;
  -moz-transition: opacity 0.25s ease-in-out;
  -webkit-transition: opacity 0.25s ease-in-out;
}
.VideoContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  width: 100%;

  white-space: nowrap;
  overflow: hidden;
}
.VideoContainer p {
  text-align: left;
  margin: 1%;
  width: 20vw;

  padding-left: 2vw;
  font-family: Font1;
  color: #fff;
  font-size: 10pt;

  text-overflow: ellipsis;
}
#videoElement {
  width: 100vw;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: stretch;
}

.BotViewer h1 {
  width: 100%;
  text-align: left;
  padding-left: 5%;
}
.BotViewer {
  background-color: var(--box-color);
  padding-top: 0%;
  height: 50%;
  width: 98vw;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: baseline;
  align-content: center;
  padding-bottom: 1%;
  border-radius: 10px;
  margin-bottom: 1rem;
}
.HomePage-Container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: stretch;
}
.MusicContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;
}

</style>
