<template>
  <section>
    <form class="login-form">
      <h1>Se connecter</h1>
      <h2>Adresse email :</h2>
      <input
        v-model="mail"
        type="mail"
        name="mail"
        placeholder="votremail@domaine.fr"
      />
      <h2>Mot de passe :</h2>
      <div class="passwordContainer">
        <input v-if="showPassword"
          v-model="password"
          type="text"
          name="password"
          placeholder="**********"
        />
        <input v-else
          v-model="password"
          type="password"
          name="password"
          placeholder="**********"
        />
        <img v-if="showPassword" class="showHidepswd" src="@/assets/IMGs/view.png" @click="toggleShow"/>
        <img v-else class="showHidepswd" src="@/assets/IMGs/hide.png" @click="toggleShow"/>
      </div>
      <button @click.stop.prevent="loginUser" class="login-But">
        Se connecter
      </button>
        <div class="remember">
          <input type="checkbox" id="rememberBut" name="rememberBut" />
          <label for="rememberBut">se souvenir de moi ?</label>
        </div>
    </form>
    
    <button class="switch-log-Reg">
      <h3 @click="$router.push('/register')">Pas de compte ?</h3>
    </button>
  </section>
</template>

<script setup>
import axios from "axios";
import { ref } from "vue";
import {useRouter} from 'vue-router'

    
    
const router = useRouter();
const name = ref();
const mail = ref();
const password = ref();
const showPassword = ref(false);

function toggleShow() {
      showPassword.value = !showPassword.value;
}
const loginUser = () => {
  axios
    .post("http://localhost/api/user/login", {
      email: mail.value,
      password: password.value,
    })
    .then((response) => {
      window.localStorage.setItem('auth-token', response.data)
      router.push({name: 'Home'})
    });
  };
</script>

<style scoped>
/****************DESKTOP/MOBILE****************/
@media only screen and (max-width: 800px) {/*Mobile*/
.login-But {
  margin-top: 1%;
  margin-bottom: 2%;
  background: none;
  color: var(--Text-color);
  font-family: Font1;
  width: 40vw;
  height: 4vh;
  border-radius: 2rem;
  border: 0.2rem solid var(--Text-color);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  cursor: pointer;
}
h1{
  font-size: 20px;
}
h2{
  font-size: 10px;
}
h3{
  font-size: 10px;
}
.login-form input {
  padding: 1%;
  margin: 1%;
  height: 100%;
  width: 80vw;
  color: black;
  border-radius: 1rem;
  text-align: center;
}
}
@media only screen and (min-width: 800px) {/*Desktop*/
.passwordContainer{
  width: 100%;
  display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: stretch;
}
.showHidepswd{
  position: absolute;
  right: 25%;
  height: 4vh;
}
.login-But {
  margin-top: 1%;
  margin-bottom: 2%;
  background: none;
  color: var(--Text-color);
  font-family: Font1;
  width: 8vw;
  height: 5vh;
  border-radius: 2rem;
  border: 0.2rem solid var(--Text-color);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  cursor: pointer;
}
.login-form input {
  padding: 1%;
  margin: 1%;
  height: 100%;
  width: 40vw;
  color: black;
  border-radius: 1rem;
  text-align: center;
}
}

/*********************CSS*********************/
.remember{
  width: 100%;
  display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: stretch;
  padding-bottom: 2%;
}
#rememberBut{
  width: 2%;
  margin: 0;
}
.remember label{
  font-family: Font1;
  color: white;
}
.switch-log-Reg {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  background: none;
  border: none;
  cursor: pointer;
}
.login-form {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  background-color: var(--box-color);
  border-radius: 10px;
  margin-bottom: 1rem;
  width: 98vw;
  margin: auto;
}



</style>
