import axios from 'axios'
import { router } from '@/router'

let apiEndpoint = axios.create({
  baseURL: 'http://localhost/api',
  headers: {
    Authorization: `Bearer ${window.localStorage.getItem('auth-token')}`
  }
})

export default apiEndpoint
