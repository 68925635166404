<template>

    <div v-if="!minimized" class="Player-Container">
      <div class="timeLine">
        <div class="progress-bar">
              <div class="cursorTimeLine"/>
        </div>
      </div>
        <div class="left-Player">
                <img class="Thumbnail" src="../../public/Thumbnails/2.jpg"/>
                <div class="infos">
                    <h2 class="title">"dream"- rap Type Beat [Prod DEON On The Track]</h2>
                    <div class="subInfos">
                        <h4>DEON On The Track</h4>
                        <h4>•</h4>
                        <h4 class="views">83 vues</h4>
                    </div>
                </div>
                <div class="SongOptions">
                    <img class="midBut" src="@/assets/IMGs/playerBar/like.png" title="aimer"/>
                    <h2  title="plus d'options">•••</h2>
                    <div class="buyBeat" title="acheter">
                        <img class="imageInfo" src="@/assets/IMGs/playerBar/shop.png"/>
                        <span>$29.99</span>
                    </div>
                </div>
        </div>
        <div class="mid-Player">
            <img class="midBut" src="@/assets/IMGs/playerBar/shuffle.png" title="aléatoire"/>
            <img class="midBut" src="@/assets/IMGs/playerBar/previous.png" title="son précedent"/>
            <img class="PlayBut" src="@/assets/IMGs/playerBar/play.png" title="Jouer"/>
            <img class="midBut" src="@/assets/IMGs/playerBar/next.png" title="son suivant"/>
            <img class="midBut" src="@/assets/IMGs/playerBar/replay.png" title="lecture en boucle"/>
        </div>
        
        <div class="right-Player">
            <img class="midBut" src="@/assets/IMGs/playerBar/sound.png" title="muet"/>
            <div class="soundVol">
                
                <div class="sliderVol"></div>
            </div>
            <img class="midBut" src="@/assets/IMGs/playerBar/share.png" title="partager"/>
            <img class="midBut" src="@/assets/IMGs/playerBar/down.png" @click="toggleMinimize" title="reduire"/>

        </div>
      <h4>3:42</h4>
    </div>
    <div class="minimized" v-else>
        <img class="minimizer" src="@/assets/IMGs/playerBar/down.png" @click="toggleMinimize" title="reduire"/>
      <div class="timeLine">
        <div class="progress-bar">
          <div class="cursorTimeLine"/>
        </div>
      </div>
    </div>
</template>

<script setup>
import { ref } from "vue";

const minimized = ref(false);

const toggleMinimize = () => {
    minimized.value = !minimized.value
}


</script>

<style scoped>
.minimized{
  width: 100%;
  height: 2vh;
}
.cursorTimeLine{
  position: absolute;
  bottom: -.2rem;
  background-color: white;
  right: 0;
  width: .6rem;
  height: .6rem;
  border-radius: 1rem;
}
.progress-bar{
  position: absolute;
  top: 0;
  width: 20%;
  height: 0.5vh;
  background-color: #0dbc79;
}
.timeLine{
  position: absolute;
  top: 0;
  background-color: black;
  width: 100%;
  height: 0.5vh;
}
.sliderVol{
    background-color: white;
    width: 7vw;
    height: 1vh;
    border-radius: 3rem;
}
.soundVol{
    width: 10vw;
    height: 1vh;
    background-color: black;
    border-radius: 3rem;
}
span{
    color: #FFF;
    font-family: Font1;
    font-size: 10pt;
}
.imageInfo{
    height: 2vh;
}
.buyBeat:hover{
    opacity: 50%;
}
.buyBeat{
    
    background-color: var(--green-color);
    width: 5vw;
    height: 4vh;
    border-radius: 10px;
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-evenly;
	align-items: center;
	align-content: stretch;
}
.SongOptions{
    padding-left: 5%;
    width: 20vw;
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: center;
	align-content: stretch;
}
.views{
    text-align: right;
}
.subInfos{
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: center;
	align-content: stretch;
    overflow:hidden;
    white-space: nowrap;
    text-overflow: clip;
    text-align: center;
}
.infos{
    width: 10vw;
    margin: 1%;
}
h4:hover{
    cursor: pointer;
    overflow:visible;
    
}
h4{
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 5vw;
    font-size: 10pt;
    font-family: Font1;
    color: rgb(95, 95, 95);
}
.title:hover{
    cursor: pointer;
    overflow:visible;
    
}
.title{
    overflow:hidden;
    white-space: nowrap;
    font-size: 12pt;
    text-overflow: clip;
    font-family: Font1;
    color: white;
}
.Thumbnail{
    height: 8vh;
    padding-right: 5%;
}
.PlayBut{
    height: 5vh;
    padding: 2%;
}
.PlayBut:hover{
    opacity: 50%;
}
.midBut:hover{
    opacity: 50%;
}
.midBut{
    height: 2vh;
    padding: 2%;
}
.left-Player{
    width: 30vw;
    
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
	align-content: stretch;
}
.mid-Player{
    width: 30vw;
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: stretch;
}
.right-Player{
    width: 30vw;
    
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-end;
	align-items: center;
	align-content: stretch;
}
.Player-Container {
    align-self: center;
    position: fixed;
    z-index: 10 !important;
    bottom: 0;
    background-color: var(--box-color);
    border-top: solid .2rem black;
    
    height: 10vh;
    width: 100%;
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: stretch;
}

.minimizer{
    position: fixed;
    bottom: 25px;
    right: 25px;
    width: fit-content;
    background-color: var(--box-color);
}

.minimizer {
    transform: rotate(180deg);
}
</style>