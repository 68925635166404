<template>
    <div class="Bot-bar">
      <div class="Bot-bar-Left"><!-- partie gauche de la Bot bar -->
        <div class="Bot-bar-link" @click="$router.push('/mentionslegales')">
          <h3>mentions légales<span class="underline"></span></h3>
        </div>
      </div>
      <div class="Bot-bar-Middle">
        <img src="@/assets/IMGs/NewLogoXLR.png" alt="Logo" class="Bot-bar-logo" @click="$router.push('/')"/>
      </div>
      <div class="Bot-bar-Right"><!-- partie droite de la Bot bar -->
        <div class="social-media-icons">
            <div class="but_res" onclick="location.href='https://www.instagram.com/deon_on_the_track/';"><img class="reseauxImg" src="@/assets/Réseaux/instagram.png"/></div>
            <div class="but_res" onclick="location.href='https://airbit.com/DEONOnTheTrack';"><img class="reseauxImg" src="@/assets/Réseaux/airbit.png"/></div>
            <div class="but_res" onclick="location.href='https://www.youtube.com/channel/UC-619uk-deuED3M6aAy9sgA';"><img class="reseauxImg" src="@/assets/Réseaux/youtube.png"/></div>
            <div class="but_res" onclick="location.href='https://www.beatstars.com/jodeon';"><img class="reseauxImg" src="@/assets/Réseaux/beatstars.png"/></div>
        </div>
      </div>
    </div>
    
</template>

<style scoped>
/****************DESKTOP/MOBILE****************/
@media only screen and (max-width: 800px) {/*Mobile*/
.Bot-bar {
  
  width: 98vw;
  height: 16vh;
  margin: auto;
  display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: space-evenly;
	align-items: center;
	align-content: center;
  background-color: var(--box-color);
  border-radius: 10px;
  margin-bottom: 1rem;
}
.Bot-bar-Middle {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: stretch;
  height: 5vh;
  width: 100%;
}
.Bot-bar-Left {
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  height: 5vh;
  
}
h3{
  font-size: 10px;
}
.Bot-bar-Right {
  display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: stretch;
	align-content: stretch;
  width: 100vw;
  height: 10vh;
}
.social-media-icons {
  display: flex;
  justify-content: space-evenly;
  text-align: center;
}
.reseauxImg{
  width: 8vw;
  height: auto;
}
}


@media only screen and (min-width: 800px) {/*Desktop*/
.Bot-bar {
  width: 98vw;
  height: 15vh;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  background-color: var(--box-color);
  border-radius: 10px;
  margin-bottom: 1rem;
}
.Bot-bar-Middle {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: stretch;
}
.Bot-bar-Left {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: stretch;
  width: 20vw;
}
.Bot-bar-Right {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: stretch;
  width: 20vw;
}
.social-media-icons {
  display: flex;
  justify-content: space-evenly;
  text-align: center;
}
.reseauxImg{
  width: 2vw;
  height: auto;
}
}
/**********************CSS**********************/


.social-media-icons a {
  margin: 0 5px;
}



.but_res{
  cursor: pointer;
  margin: 5%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}





.Bot-bar-Right li {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: -webkit-match-parent;
  unicode-bidi: isolate;
}

.Bot-bar-logo {
  width: 30vh;
  cursor: pointer;
  z-index: 4 !important;
}

.contact-but {
  width: 7.5vw;
  height: 5vh;
  border-radius: 20rem;
  background-color: var(--orange-color);
  border: .2rem solid var(--orange-color);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  color: var(--box-color);
}

.Login-But h3 {
  color: var(--Text-color);
}

.Login-But {
  width: 8vw;
  height: 5vh;
  border-radius: 20rem;
  border: .2rem solid var(--Text-color);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  cursor: pointer;
}



.Bot-bar h3 {
  white-space: nowrap;
  position: relative;
  overflow: hidden;
}

.Bot-bar h3 .underline {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: var(--Text-color);
  transition: width 0.7s ease-out;
}

.Bot-bar h3:hover .underline {
  width: 100%;
}


</style>
