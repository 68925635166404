<template>
  <div class="account-Container">
  <div class="accountInfos">
    <h2>Vos Informations</h2>
    <div class="Avatar">
        <img class="userAvatar" src="@/assets/IMGs/profile-user.png"/>
        <div class="changeAvatar">
            <h3>Choisir un avatar</h3>
        </div>
    </div>
    <h2 v-if="user">Nom et Prénom: <span>{{user.name}}</span></h2>
    <h2 v-if="user">Adresse email : <span>{{user.email}}</span></h2>
  </div>
  <div class="infosModif">
        <form class="register-form">
      <h2>Modifier les informations</h2>
      <h3>Nom et Prénom :</h3>
      <input
        v-model="name"
        type="text"
        name="name"
        placeholder="Nom Prénom"
      />
      <h3>Adresse email :</h3>
      <input
        v-model="mail"
        type="mail"
        name="mail"
        placeholder="votremail@domaine.fr"
      />
      <h3>Mot de passe :</h3>

      <div class="passwordContainer">
        <input v-if="showPassword"
          v-model="password"
          type="text"
          name="password"
          placeholder="**********"
        />
        <input v-else
          v-model="password"
          type="password"
          name="password"
          placeholder="**********"
        />
        <img v-if="showPassword" class="showHidepswd" src="@/assets/IMGs/view.png" @click="toggleShow"/>
        <img v-else class="showHidepswd" src="@/assets/IMGs/hide.png" @click="toggleShow"/>
      </div>
      
      <h3>Confirmer mot de passe :</h3>
      <input
        v-model="confirm"
        type="password"
        name="confirm"
        placeholder="**********"
      />
      <button @click.stop.prevent="registerUser" class="register-But">
        accepter les modifications
      </button>
      
    </form>

  </div>
  </div>
</template>

<script setup>
    import { onMounted } from "vue";
    import { ref } from "vue";

    const showPassword = ref(false);
    let user = ref(null);
    
    function toggleShow() {
      showPassword.value = !showPassword.value;
    }
    onMounted(() => {
      
      user.value = JSON.parse(window.localStorage.getItem('user'));
    
    })

</script>

<style scoped>
.passwordContainer{
  width: 100%;
  display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: center;
	align-content: stretch;
}
.showHidepswd{
  
  height: 4vh;
}
span{
    color: #a8a8a8;
    font-size: 12pt;
}
.register-But:hover{
    opacity: 50%;
}
.register-But {

  margin-top: 3%;
  margin-bottom: 2%;
  background: none;
  color: var(--Text-color);
  font-family: Font1;
  width: 15.2vw;
  height: 3vh;
  border-radius: 2rem;
  border: 0.2rem solid var(--Text-color);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  cursor: pointer;
}
h3{
    margin: 0;
    margin-top: 5%;
    margin-bottom: 1%;
}
.register-form {
  display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: flex-start;
	align-content: stretch;
  background-color: var(--box-color);
  border-radius: 10px;
  margin-bottom: 2rem;
  width: 18vw;
  height: 55vh;
  margin: auto;
}
.register-form input {
  
  margin: 0;
  height: 3vh;
  width: 15vw;
  border: none;
  color: black;
  border-radius: 1rem;
  text-align: center;
}
.changeAvatar h3{
    margin: 0;
    text-align: center;
    font-size: 10pt;
}
.changeAvatar:hover{
    cursor: pointer;
    opacity: 50%;
}
.changeAvatar{
    background-color: rgb(34, 34, 34);
    height: 3vh;
    align-content: center;
    width: 100%;
    border-radius:0 0 .7rem .7rem;
}

.Avatar{
    background-color: black;
    border-radius: 1rem;
    
    margin: 2%;
    width: 15rem;
    height: 15rem;
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: space-evenly;
	align-items: center;
	align-content: center;
    
}
.userAvatar{
    padding: 2%;
    height: 15vh;
    margin: auto;
}
.accountInfos{
    color: #FFF;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 55vh;
    width: 20vw;
}
.account-Container{
    width: 98vw;
    
    margin: auto;
    background-color: var(--box-color);
    border-radius: 10px;
    margin-bottom: 1rem;
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-evenly;
	align-items: center;
	align-content: center;
    
}
</style>