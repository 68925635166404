<template>
  
  <div class="propos-img-container">
    <h1>deonstudio :</h1>
  <p>
    Studio d’enregistrement situé au cœur de Valence(26), DEON Studio vous
    accueille dans leur studio d'enregistrement pour vos projets de l’Industrie
    Musicale, nous mettons à votre disposition notre savoir-faire ainsi que
    notre expérience, pour vous permettre de réaliser vos projets dans des
    conditions techniques optimales.
  </p>
    <img class="img-apropos" src="../assets/IMGs/1.jpg" />
    <img class="img-apropos" src="../assets/IMGs/2.jpg" />
  </div>
</template>

<script>
</script>

<style scoped>
/****************DESKTOP/MOBILE****************/
@media only screen and (max-width: 800px) {/*Mobile*/
h1{
    text-align: center;
    margin: auto;
    width: 50%;
    padding-top: 5%;
    padding-bottom: 2%;
    font-size: 20px;
}
p{
    text-align: center;
    margin: auto;
    width: 80%;
    font-size: 10px;
}
.img-apropos{
  border-radius: .5rem;
  margin-top: 10%;
  margin: auto;
  cursor: pointer;
  width: 70%;
  padding: 1%;
}
.propos-img-container{
  background-color: var(--box-color);
  padding-top: 0%;
  height: 50%;
  width: 98vw;
  margin: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: baseline;
  align-content: center;
  padding-bottom: 1%;
  border-radius: 10px;
  margin-bottom: 1rem;
}
}

@media only screen and (min-width: 800px) {/*Desktop*/
h1{
    text-align: center;
    margin: auto;
    width: 50%;
    padding-top: 5%;
    padding-bottom: 2%;
}
p{
    text-align: center;
    margin: auto;
    width: 50%;
}
.img-apropos{
  border-radius: 2rem;
  margin-top: 10%;
  margin: auto;
  cursor: pointer;
  width: 50%;
  padding: 1%;
}
.propos-img-container{
  background-color: var(--box-color);
  padding-top: 0%;
  height: 50%;
  width: 98vw;
  margin: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: baseline;
  align-content: center;
  padding-bottom: 1%;
  border-radius: 10px;
  margin-bottom: 1rem;
}
}

/*********************Fonts*********************/

</style>