<template>
    <div class="account-bar">
        <div class="userAccount" @click="$router.push('/ClientAccount')"><img class="userAvatar" src="@/assets/IMGs/profile-user.png"/><span>mon compte</span></div>

        <span v-if="!user"></span>
        <h2 class="userName" v-else>Bienvenue {{user.name}} !</h2>

        <div class="right-Account" @click="logout">
            <span>deconnexion</span>
            <img class="unconnect" src="@/assets/IMGs/unplug.png"/>
            
        </div>

    </div>
    <div class="listClient-Container">
        <div class="clientList">
            <!-- <span v-for="customersList in customersList">{{customersList.name}}</span> -->
            <ul class="List" v-if="customersList.length > 0">
                <span v-for="customer in customersList" :key="customer.id" @click="selectCustomer(customer)">
                    {{ customer.name }}
                </span>
            </ul>
        <p v-else>Aucun Client trouvé.</p>
  

        </div>
        <div class="clientInfos">
            <img class="userAvatar" src="@/assets/IMGs/profile-user.png"/>
            <h2 v-if="selectedCustomer">Nom et Prénom: <span>{{selectedCustomer.name}}</span></h2>
            <h2 v-if="selectedCustomer">Adresse email : <span>{{selectedCustomer.email}}</span></h2>
            <p v-if="!selectedCustomer">Sélectionnez un client pour voir ses informations.</p>
        </div>
    </div>
    <div class="dashboard-container">
        <div class="ProjectFolders">
                <h2>Folders</h2>
                <div class="Folder"><img  src="@/assets/IMGs/folder.png" /><h3>Folder 1/</h3></div>
                <div class="Folder"><img  src="@/assets/IMGs/folder.png" /><h3>Folder 2/</h3></div>
                <div class="Folder"><img  src="@/assets/IMGs/folder.png" /><h3>Folder 3/</h3></div>
                <div class="Folder"><img  src="@/assets/IMGs/folder.png" /><h3>Folder 4/</h3></div>
                <div class="Folder"><img  src="@/assets/IMGs/folder.png" /><h3>Folder 5/</h3></div>
                <div class="Folder"><img  src="@/assets/IMGs/folder.png" /><h3>Folder 6/</h3></div>
            
        </div>
        <div class="ProjectFiles">
                <h2>Files</h2>
                <div class="File"><img  src="@/assets/IMGs/fichier.png" /><h3>File 1.wav</h3></div>
                <div class="File"><img  src="@/assets/IMGs/fichier.png" /><h3>File 1.wav</h3></div>
                <div class="File"><img  src="@/assets/IMGs/fichier.png" /><h3>File 1.wav</h3></div>
                <div class="File"><img  src="@/assets/IMGs/fichier.png" /><h3>File 1.wav</h3></div>
                <div class="File"><img  src="@/assets/IMGs/fichier.png" /><h3>File 1.wav</h3></div>
                <div class="File"><img  src="@/assets/IMGs/fichier.png" /><h3>File 1.wav</h3></div>
                <div class="File"><img  src="@/assets/IMGs/fichier.png" /><h3>File 1.wav</h3></div>
                <div class="File"><img  src="@/assets/IMGs/fichier.png" /><h3>File 1.wav</h3></div>
                <div class="File"><img  src="@/assets/IMGs/fichier.png" /><h3>File 1.wav</h3></div>
                <div class="File"><img  src="@/assets/IMGs/fichier.png" /><h3>File 1.wav</h3></div>
                <div class="File"><img  src="@/assets/IMGs/fichier.png" /><h3>File 1.wav</h3></div>
                <div class="File"><img  src="@/assets/IMGs/fichier.png" /><h3>File 1.wav</h3></div>
            
        </div>
        
    </div>
    <div class="manageCustomer">
        <div class="manageFolders">
            <div class="button createFolder">
                <span class="spanText">nouveau dossier</span>
            </div>
            <div class="button deleteFolder">
                <span class="spanText">effacer dossier</span>
            </div>
        </div>
        <div class="manageFiles">
            <div class="button addFile">
                <span class="spanText">ajouter fichier</span>
            </div>
            <div class="button deleteFile">
                <span class="spanText">effacer fichier</span>
            </div>

        </div>
            
            
        </div>
    <div class="message-Container">
        <h2 v-if="selectedCustomer">Conversation avec {{selectedCustomer.name}}</h2>
        <h2 v-else>Aucun Client selectioné.</h2>
        <div class="messageBox">
            <div class="messageBox-content" v-if="selectedCustomer">
                <div class="message-bulb AdminMessage"><label id="Time">10/08/2024 19:22</label><span>un premier message Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?</span></div>
                <div class="message-bulb ClientMessage"><span>un message</span><label id="Time">10/08/2024 19:22</label></div>
                <div class="message-bulb AdminMessage"><label id="Time">10/08/2024 19:22</label><span>un message ifhdufhdhsfgsdh</span></div>
                <div class="message-bulb ClientMessage"><span>un message</span><label id="Time">10/08/2024 19:22</label></div>
                <div class="message-bulb AdminMessage"><label id="Time">10/08/2024 19:22</label><span>un message ifhdufhdhsfgsdh</span></div>
                <div class="message-bulb ClientMessage"><span>un message</span><label id="Time">10/08/2024 19:22</label></div>
                <div class="message-bulb AdminMessage"><label id="Time">10/08/2024 19:22</label><span>un message ifhdufhdhsfgsdh</span></div>
                <div class="message-bulb ClientMessage"><span>un message</span><label id="Time">10/08/2024 19:22</label></div>    
                <div class="message-bulb AdminMessage"><label id="Time">10/08/2024 19:22</label><span>un message ifhdufhdhsfgsdh</span></div>
                <div class="message-bulb ClientMessage"><span>un message</span><label id="Time">10/08/2024 19:22</label></div>  
                <div class="message-bulb AdminMessage"><label id="Time">10/08/2024 19:22</label><span>un message ifhdufhdhsfgsdh</span></div>
                <div class="message-bulb ClientMessage"><span>un message</span><label id="Time">10/08/2024 19:22</label></div>  
                <div class="message-bulb AdminMessage"><label id="Time">10/08/2024 19:22</label><span>un message ifhdufhdhsfgsdh</span></div>
                <div class="message-bulb ClientMessage"><span>un message</span><label id="Time">10/08/2024 19:22</label></div>  
                <div class="message-bulb AdminMessage"><label id="Time">10/08/2024 19:22</label><span>un message ifhdufhdhsfgsdh</span></div>
                <div class="message-bulb ClientMessage"><span>un message</span><label id="Time">10/08/2024 19:22</label></div>  
                <div class="message-bulb AdminMessage"><label id="Time">10/08/2024 19:22</label><span>un message ifhdufhdhsfgsdh</span></div>
                <div class="message-bulb ClientMessage"><span>un message</span><label id="Time">10/08/2024 19:22</label></div>  
                <div class="message-bulb AdminMessage"><label id="Time">10/08/2024 19:22</label><span>un message ifhdufhdhsfgsdh</span></div>
                <div class="message-bulb ClientMessage"><span>un  dernier message</span><label id="Time">10/08/2024 19:22</label></div>  

            </div>
            <div class="messageBox-content" v-else>
                <span>Pas de conversation trouvé</span>
            </div>
        </div>
        <div class="ClientInputContainer">
            <input id="userMessage" placeholder="   Message..."/>
            <img id="Send" src="@/assets/IMGs/send.png"/>
        </div>
    </div>
</template>

<script setup>

import { ref, onMounted } from "vue";
import {useRouter} from 'vue-router';
import axios from 'axios';
import apiEndpoint from "@/plugins/axios";



let user = ref(null);
let customersList = ref([]);
const selectedCustomer = ref(null);

const router = useRouter();
    function logout(){
        window.localStorage.removeItem('user');
        window.localStorage.removeItem('auth-token');
        
            
    }

user.value = JSON.parse(window.localStorage.getItem('user'));

const fetchCustomers = async () => {
  try {
    const response = await apiEndpoint.get('/user/customersList');
    customersList.value = response.data;
  } catch (error) {
    console.error('Erreur lors de la récupération des utilisateurs:', error);
    // Gérez l'erreur comme vous le souhaitez (par exemple, afficher un message à l'utilisateur)
  }
};
const selectCustomer = (customer) => {
  selectedCustomer.value = customer;
};


onMounted(() => {
  fetchCustomers();
});
</script>

<style scoped>
@media only screen and (max-width: 800px) {/*Mobile*/

}
@media only screen and (min-width: 800px) {/*Desktop*/

.List{
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: flex-start;
	align-content: center;
    width: 100%;
    padding: 0;
    margin: 0;
}
.button:hover{
    cursor: pointer;
    opacity: 50%;
}
.button{
    background: #0dbc79;
    width: 40%;
    height: 5vh;
    border-radius: .5rem;
    margin: auto;
    align-content: center;
    margin-top: 3%;
    margin-bottom: 3%;
}
.manageCustomer{
    
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-evenly;
	align-items: center;
	align-content: center;
    
    
}
.spanText{
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
}
.manageFolders{
    width: 48.5vw;
    margin: auto;
    background-color: var(--box-color);
    border-radius: 10px;
    margin-bottom: 1rem;
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: center;
    
}
.manageFiles{
    width: 48.5vw;
    margin: auto;
    background-color: var(--box-color);
    border-radius: 10px;
    margin-bottom: 1rem;
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: center;
}
.clientInfos{
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
	align-content: center;
}
.clientInfos .userAvatar{
    height: 20vh;
}
.clientList span:hover{
    cursor: pointer;
    opacity: 50%;
}
.clientList span{
    margin: 1%;
}
.clientList{
    background-color: #000;
    border-radius: 10px;
    margin-bottom: 1rem;
    border: Solid 2px #1a1a1a;
    width: 30%;
    height: 30vh;
    padding:  0.5rem;
    overflow-y: auto;
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: flex-start;
	align-content: center;
}
.listClient-Container{
    width: 98vw;
    margin: auto;
    background-color: var(--box-color);
    border-radius: 10px;
    margin-bottom: 1rem;
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-evenly;
	align-items: flex-start;
	align-content: flex-start;
    padding-top: 1%;
    margin-top: 0;
    padding-bottom: 1%;
}
.userAccount:hover{
    cursor: pointer;
    opacity: 50%;
}
.userAccount{
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-evenly;
	align-items: center;
	align-content: center;
    width: 15vw;
}
.unconnect{
    height: 5vh;
}
.userAvatar{
    height: 5vh;
    
}
#Send{
    height: 5vh;
    padding-left: 1%;
    

}
#Send:hover{
    opacity: 50%;
}
.ClientInputContainer{
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: center;
	align-content: center;
    width: 90%;
}
#userMessage{
  padding-left: 1%;
  font-family: Font1;
  border: Solid 2px #1a1a1a;
  height: 5vh;
  width: 100%;
  background-color: black;
  color: #FFF;
  border-radius: 1rem;
  text-align: left;
}
.messageBox-content {
    display: flex;
    flex-direction: column;
    height: 70vh;
    
    overflow-y: auto;
    
}
.message-bulb {
    max-width: 50%;
    overflow-wrap: break-word;
    display: flex;
    align-items: center;
    padding: 15px;
    min-width: 15%;
}

.message-bulb > span {
    width: 100%;
    line-height: 1.5rem;
}

.AdminMessage{
    background-color: #0dbc79;
    border-radius: 10px;
    margin-bottom: 1rem;
    align-self: flex-start;
}

.ClientMessage{
    background-color: var(--box-color);
    border-radius: 10px;
    margin-bottom: 1rem;
    align-self: flex-end;
}
.messageBox{
    background-color: #000;
    border-radius: 10px;
    margin-bottom: 1rem;
    border: Solid 2px #1a1a1a;
    width: 90%;
    padding:  0.5rem;
}
.message-Container{
    
    width: 98vw;
    margin: auto;
    background-color: var(--box-color);
    border-radius: 10px;
    margin-bottom: 1rem;
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
	align-content: center;
    padding-top: 1%;
    margin-top: 0;
    padding-bottom: 1%;
    
}

span{
    color: #FFF;
    font-family: Font1;
    
}
#Time{
    font-family: Font2;
    color: rgb(73, 73, 73);
    padding: 0 1rem;
    text-wrap: nowrap;
    font-size: 0.8rem;
}
.userName{
    text-align: center;
}
.right-Account:hover{
    cursor: pointer;
    opacity: 50%;
}
.right-Account{
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-evenly;
	align-items: center;
	align-content: center;
    
    width: 15vw;
}
.logout{
    height: 5vh;
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: stretch;
}
.account-bar{
    width: 98vw;
    margin: auto;
    background-color: var(--box-color);
    border-radius: 10px;
    margin-bottom: 1rem;
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-evenly;
	align-items: center;
	align-content: stretch;
    padding-top: 1%;
    margin-top: 0;
    padding-bottom: 1%;
}

.File img{
    width: 3vw;
    padding-left: 2%;
}
.File{
    background-color: #000;
    border-radius: 10px;
    margin-bottom: 1rem;
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
	align-content: center;
}
.File:hover{
    cursor: pointer;
    background-color: #2424244d;
}
.Folder:hover{
    cursor: pointer;
    background-color: #2424244d;
    
}
.Folder img{
    width: 3vw;
    padding-left: 2%;
}
.Folder{
    background-color: #000;
    border-radius: 10px;
    margin-bottom: 1rem;
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
	align-content: center;
}
.dashboard-container{
    
    /* width: 98vw; */
    margin: auto;
    /* background-color: var(--box-color);
    border-radius: 10px;
    margin-bottom: 1rem; */
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-evenly;
	align-items: center;
	align-content: center;
    padding-top: 1%;
    margin-top: 0;
    padding-bottom: 1%;
}
.ProjectFolders{
    
    padding: 0.5%;
    width: 47.5vw;
    margin: auto;
    background-color: var(--box-color);
    border: Solid 2px var(--box-color);
    border-radius: 10px;
    margin-bottom: 1rem;
    height: 70vh;
    margin: auto;
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: stretch;
	align-content: center;
    overflow-y: auto;
    scrollbar-color:  rgb(0, 0, 0) #121212;
    scrollbar-width: thin;
}
.ProjectFiles{
    
    padding: 0.5%;
    width: 47.5vw;
    margin: auto;
    background-color: var(--box-color);
    border: Solid 2px var(--box-color);
    border-radius: 10px;
    margin-bottom: 1rem;
    height: 70vh;
    margin: auto;
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: stretch;
	align-content: center;
    overflow-y: auto;
    scrollbar-color:  rgb(0, 0, 0) #121212;
    scrollbar-width: thin;
}
}
</style>