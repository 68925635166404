<template>
  <body>
    <NavBar />
    <router-view @play-music="showPlayer"/>
    <BotBar />
    <router-view v-if="playerToggle" name="PlayerBar"/>
    <div class="cookies-background">
      <div class="Cookies-container" id="cookiesPopup">
        <div class="Cookies-Frame">
          <img class="CookieImg" src="@/assets/IMGs/cookie-b.png" />
          <p>
            Ce site utilise des cookies pour améliorer votre expérience. En
            continuant à naviguer sur le site, vous acceptez notre utilisation
            des cookies.
          </p>
          <button id="acceptCookies"><a>accepter</a></button>
          <button id="refCookies"><a>refuser</a></button>
        </div>
      </div>
    </div>
    
  </body>
</template>

<script setup>

import NavBar from "@/components/NavBar.vue";
import BotBar from "@/components/BotBar.vue";
import PlayerBar from "@/components/PlayerBar.vue";
import { ref } from "vue";

const playerToggle = ref(false);
///////////////////////////////////////////////////////////////////////////////je veux que ce soit global a toutes les pages

function showPlayer() {
  playerToggle.value = true;
}


document.addEventListener("DOMContentLoaded", function () {
  // Fonction pour afficher la popup des cookies
  function showCookiesPopup() {
    const cookiesPopup = document.getElementById("cookiesPopup");
    cookiesPopup.style.display = "block";
  }

  // Fonction pour masquer la popup des cookies et enregistrer l'acceptation des cookies
  function hideCookiesPopup() {
    const cookiesPopup = document.getElementById("cookiesPopup");
    cookiesPopup.style.display = "none";

    // Enregistrez l'acceptation des cookies (vous pouvez utiliser des cookies ou localStorage)
    localStorage.setItem("cookiesAccepted", "true");
  }
  // Fonction pour masquer la popup des cookies et ne pas enregistrer les cookies
  function hiderefCookiesPopup() {
    const cookiesPopup = document.getElementById("cookiesPopup");
    cookiesPopup.style.display = "none";
  }

  // Vérifiez si l'utilisateur a déjà accepté les cookies
  const cookiesAccepted = localStorage.getItem("cookiesAccepted");
  if (!cookiesAccepted) {
    showCookiesPopup();
  }

  // Écoutez le clic sur le bouton "Accepter" pour masquer la popup
  const acceptCookiesButton = document.getElementById("acceptCookies");
  acceptCookiesButton.addEventListener("click", hideCookiesPopup);

  // Écoutez le clic sur le bouton "Refuser" pour masquer la popup
  const refCookiesButton = document.getElementById("refCookies");
  refCookiesButton.addEventListener("click", hiderefCookiesPopup);
});
</script>

<style>
/*****************COLOR PALLETTE*****************/
:root {
  --orange-color: #ff9900;
  --Back-color: #080931;
  --box-color: #121212;
  --Text-color: #eeeeee;
  --TextBox-color: #b3b3b3;
  --green-color:#0dbc79;
}

/****************DESKTOP/MOBILE****************/
@media only screen and (max-width: 800px) {/*Mobile*/
}
@media only screen and (min-width: 800px) {/*Desktop*/
}

/*********************Fonts*********************/

@font-face {
  font-family: "Font1";
  src: url("./Fonts/deonstudio.otf") format("opentype");
}
@font-face {
  font-family: "Font2";
  src: url("./Fonts/Roboto.ttf") format("opentype");
}
h3 {
  font-size: 1vw;
  font-family: Font1;
  color: var(--Text-color);
  cursor: pointer;
  margin: 2vw;
}
h2 {
  font-size: 1vw;
  font-family: Font1;
  color: var(--Text-color);
}
h1 {
  font-size: 2vw;
  font-family: Font1;
  color: var(--Text-color);
}
p {
  font-size: 1vw;
  font-family: Font2;
  color: var(--Text-color);
}
#refCookies {
  width: 7.5vw;
  height: 5vh;
  border-radius: 20rem;
  background-color: var(--orange-color);
  border: 0.2rem solid var(--orange-color);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  color: var(--box-color);
}
#refCookies a {
  color: var(--box-color);
  font-family: Font1;
  font-size: 1rem;
}
#acceptCookies {
  background: none;
  width: 8vw;
  height: 5vh;
  border-radius: 20rem;
  border: 0.2rem solid var(--Text-color);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  cursor: pointer;
}
#acceptCookies a {
  color: var(--Text-color);
  font-family: Font1;
  font-size: 1rem;
}
.cookies-background {
  width: 50%;
  height: 50%;
  
  display: flex;
}
.Cookies-Frame p {
  width: 50%;
}
.Cookies-Frame {
  z-index: 3;
  width: 100%;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: stretch;
}
#cookiesPopup {
  height: 20vh;
  border-radius: 10px;
  z-index: 5;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(145deg, #5f5d5d, #3b3737);
  box-shadow: 7px 7px 14px #1b1818, -7px -7px 14px #292626;
  /*padding: 2%;*/
  text-align: center;
  display: none;
}
.CookieImg {
  background: cover;
  width: 5%;
  padding: 5%;
  padding-right: 0;
}


body {
  margin: 0;
  margin-top: 0.5%;
  cursor: default;
  background: black;
}
</style>
