<template>
  <div class="contact">
    <h1>Contactez nous!</h1>
    <p>
      n'hésitez pas à nous contacter pour toutes informations complémentaires
    </p>
    <form class="Formulaire" ref="form" @submit.prevent="sendEmail">
      <label> Nom et Prénom</label>
      <input class="user_name" type="text" name="user_name" placeholder="Nom Prénom"/>
      <label>Adresse email</label>
      <input class="mail" type="email" name="user_email" placeholder="votremail@monmail.fr" />
      <label>message</label>
      <textarea class="message" name="message" placeholder="Votre message..."></textarea>
      <button class="submit" type="submit"><h3>Envoyer</h3></button>
      <div class="email_not_send"></div>
    </form>

    <p>mail: contact@deonstudio.fr</p>
  </div>
</template>

<script>
import emailjs from "@emailjs/browser";

export default {
  methods: {
    sendEmail() {
      let divName = document.querySelector(".user_name");
      let divMsg = document.querySelector(".message");
      let divMail = document.querySelector(".mail");

      if (divName.value != "" && divMsg.value != "" && divMail.value != "") {
        const div = document.querySelector(".email_not_send");
        div.innerHTML =
          "Envoyé avec succès, merci " + divName.value + " pour ton message!";
        div.style.color = "#fff";

        emailjs
          .sendForm(
            "service_ghmd05f",
            "template_7v3nfl4",
            this.$refs.form,
            "72lEXY0ZsZQV2Wf_1"
          )
          .then(
            (result) => {
              console.log("SUCCESS!", result.text);
            },
            (error) => {
              console.log("FAILED...", error.text);
            }
          );
        divName.value = "";
        divMsg.value = "";
        divMail.value = "";
      } else {
        const div = document.querySelector(".email_not_send");
        div.innerHTML = "informations incompletes !";
        div.style.color = "#d30";
      }
    },
  },
};
</script>

<style scoped>
/****************DESKTOP/MOBILE****************/
@media only screen and (max-width: 800px) {/*Mobile*/
label {
  font-size: 4vw;
  font-family: Font1;
  color: #fff;
  margin: 1%;
}
p{
  font-size: 2vw;
}
textarea {
  padding: 1%;
  margin: 1%;
  height: 20vh;
  width: 80vw;
  color: black;
  border-radius: 1rem;
  text-align: left;
}
input {
  padding: 1%;
  margin: 1%;
  height: 100%;
  width: 80vw;
  color: black;
  border-radius: 1rem;
  text-align: center;
}
h1{
  font-size: 15px;
}
h3{
  font-size: 10px;
}
}
@media only screen and (min-width: 800px) {/*Desktop*/
label {
  font-size: 1vw;
  font-family: Font1;
  color: #fff;
  margin: 1%;
}
textarea {
  padding: 1%;
  margin: 1%;
  height: 20vh;
  width: 40vw;
  color: black;
  border-radius: 1rem;
  text-align: left;
}
input {
  padding: 1%;
  margin: 1%;
  height: 100%;
  width: 40vw;
  color: black;
  border-radius: 1rem;
  text-align: center;
}
}
/*********************CSS*********************/
.email_not_send {
  font-size: 1vw;
  padding: 2%;
  font-family: Font1;
  color: #fff;
}

.submit {
  width: 42vw;
  height: 5vh;
  border-radius: 20rem;
  background: none;
  border: .2rem solid var(--Text-color);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  cursor: pointer;
  font-family: Font1;
  
}

.submit:hover {
  width: 42vw;
  height: 5vh;
  border-radius: 20rem;
  background-color: var(--orange-color);
  border: .2rem solid var(--orange-color);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  color: var(--box-color);
  font-family: Font1;
}



form {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}
/*---------------------------- */
.contact {
    display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  background-color: var(--box-color);
  border-radius: 10px;
 
  width: 98vw;
  margin: auto;
  margin-bottom: 1%;
}


</style>