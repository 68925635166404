<template>
    <div class="Top-bar">
      <div class="Top-bar-Left"><!-- partie gauche de la top bar -->
        <img src="@/assets/IMGs/NewLogoXLR.png" alt="Logo" class="top-bar-logo" @click="$router.push('/')" title="premiere Page"/>
      </div>
      <div class="Top-bar-Middle">

        <div class="top-bar-link" @click="$router.push('/Apropos')">
          <h3>à propos<span class="underline"></span></h3>
        </div>

        <div class="top-bar-link" @click="$router.push('/outils')">
          <h3>nos outils<span class="underline"></span></h3>
        </div>
<!--        <div  class="top-bar-link" @click="$router.push('/DashboardAdmin')">
          <h3>Admin<span class="underline"></span></h3>
        </div>-->
      </div>
      <div class="Top-bar-Right"><!-- partie droite de la top bar -->
        <div v-if="!user" class="Login-But" @click="$router.push('/login')">
          <h3>log in</h3>
        </div>
        <div v-else >
          <h3 @click="$router.push('/DashboardClient')" title="mon espace">{{user.name}} <span class="underline" ></span></h3>
        </div>
        <div class="top-bar-link" @click="$router.push('/contact')" title="nous contacter">
            <h3 class="contact-but">contact</h3>
        </div>
      </div>
    </div>
    <div class="Music-Player"></div>
</template>

<script setup>
import { onMounted } from "vue";
import { ref } from "vue";


// import {useRouter} from 'vue-router'

// const router = useRouter();
let user = ref(null);
let isAdmin = ref(null); 
// router.beforeEach((to, from) => {
//   user.value = JSON.parse(window.localStorage.getItem('user'));
// })

onMounted(() => {
  user.value = JSON.parse(window.localStorage.getItem('user'));
  isAdmin.value = user.isAdmin;
  console.log(isAdmin)
})

</script>

<style scoped>
/****************DESKTOP/MOBILE****************/
@media only screen and (max-width: 800px) {/*mobile*/
  .Top-bar {
  width: 98vw;
  height: 16vh;
  margin: auto;
  display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: baseline;
	align-content: flex-start;
  background-color: var(--box-color);
  border-radius: 10px;
  margin-bottom: 1rem;
  }
  .Top-bar-Right{
  width: 100%;
  display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-evenly;
	align-items: center;
	align-content: center;
  }  
  .Top-bar-Middle {
    width: 100%;
    height: 4vh;
  display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-evenly;
	align-items: center;
	align-content: center;
  }
    .Login-But {
  width: 40vw;
  height: 3vh;
  border-radius: 20rem;
  border: .2rem solid var(--Text-color);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  cursor: pointer;
}
.contact-but{
  width: 40vw;
  height: 3vh;
  border-radius: 20rem;
  background-color: var(--orange-color);
  border: .2rem solid var(--orange-color);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  color: var(--box-color);
}
.top-bar-logo {
  
  width: 30vh;
  cursor: pointer;
  z-index: 4 !important;
}
.Top-bar-Left{
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
}
h3{
  font-size: 10px;
}
}


@media only screen and (min-width: 800px) {/*desktop*/
  .Top-bar {
  width: 98vw;
  height: 15vh;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  
  background-color: var(--box-color);
  border-radius: 10px;
  margin-bottom: 1rem;
  }
  .Top-bar-Right{
    width: 30vw;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  
  }
  .Top-bar-Middle {
    width: 30vw;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  }
  .Login-But {
  width: 8vw;
  height: 5vh;
  border-radius: 20rem;
  border: .2rem solid var(--Text-color);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  cursor: pointer;
}
.contact-but{
  width: 7.5vw;
  height: 5vh;
  border-radius: 20rem;
  background-color: var(--orange-color);
  border: .2rem solid var(--orange-color);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  color: var(--box-color);
}
.top-bar-logo {
  
  width: 30vh;
  cursor: pointer;
  z-index: 4 !important;
}
.Top-bar-Left{
  width: 30vw;

}
}
/**********************CSS**********************/


.Top-bar-Right li{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: -webkit-match-parent;
  unicode-bidi: isolate;
}





.Login-But h3 {
  color: var(--Text-color);
}





.Top-bar h3 {
  white-space: nowrap;
  position: relative;
  overflow: hidden;
}

.Top-bar h3 .underline {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  
  height: 2px;
  background-color: var(--Text-color);
  transition: width .7s ease-out;
}

.Top-bar h3:hover .underline {
  width: 100%;
  
}


</style>
