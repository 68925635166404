<template>

    <div class="mentions">
        
        <div class="mention-container">
        <!--Contenu mention legale -->
        <a>Mentions légales et politique de confidentialité</a>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        <p>L'entreprise individuelle Johann DEON, soucieuse des droits des individus, notamment au</p>
        <p>regard des traitements automatisés et dans une volonté de transparence avec ses clients, a</p>
        <p>mis en place une politique reprenant l’ensemble de ces traitements, des finalités</p>
        <p>poursuivies par ces derniers ainsi que des moyens d’actions à la disposition des individus</p>
        <p>afin qu’ils puissent au mieux exercer leurs droits.</p>
        <p>Pour toute information complémentaire sur la protection des données personnelles, nous</p>
        <p>vous invitons à consulter le site : https://www.cnil.fr/</p>
        <p>La poursuite de la navigation sur ce site vaut acceptation sans réserve des dispositions et</p>
        <p>conditions d'utilisation qui suivent.</p>
        <p>La version actuellement en ligne de ces conditions d'utilisation est la seule opposable</p>
        <p>pendant toute la durée d'utilisation du site et jusqu'à ce qu'une nouvelle version la</p>
        <p>remplace.</p>
        <p></p>
        <h3>Article 1 - Mentions légales</h3>
        <p></p>
        <p>1.1 Site (ci-après « le site ») : Deonstudio</p>
        <p>1.2 Éditeur (ci-après « l'éditeur ») :</p>
        <p>L'entreprise indivuduelle Johann DEON</p>
        <p>située : 91 avenue Sadi Carnot, 26000 Valence</p>
        <p>immatriculée au RCS de 914596218</p>
        <p>n° de téléphone : +33651003485</p>
        <p>adresse mail : contact@deonstudio.fr</p>
        <p>1.3 Hébergeur (ci-après « l'hébergeur ») :</p>
        <p>Deonstudio est hébergé par Johann DEON, dont le siège social est situé 9 rue Les</p>
        <p>Terrasses 26300 Châteauneuf sur Isère.</p>
        <p></p>
        <h3>Article 2 - Accès au site</h3>
        <p></p>
        <p>L'accès au site et son utilisation sont réservés à un usage strictement personnel. Vous vous</p>
        <p>engagez à ne pas utiliser ce site et les informations ou données qui y figurent à des fins</p>
        <p>commerciales, politiques, publicitaires et pour toute forme de sollicitation commerciale et</p>
        <p>notamment l'envoi de courriers électroniques non sollicités.</p>
        <p></p>
        <h3>Article 3 - Contenu du site</h3>
        <p></p>
        <p>Toutes les marques, photographies, textes, commentaires, illustrations, images animées ou</p>
        <p>non, séquences vidéo, sons, ainsi que toutes les applications informatiques qui pourraient</p>
        <p>être utilisées pour faire fonctionner ce site et plus généralement tous les éléments</p>
        <p>reproduits ou utilisés sur le site sont protégés par les lois en vigueur au titre de la propriété</p>
        <p>intellectuelle.</p>
        <p>Ils sont la propriété pleine et entière de l'éditeur ou de ses partenaires. Toute reproduction,</p>
        <p>représentation, utilisation ou adaptation, sous quelque forme que ce soit, de tout ou partie</p>
        <p>de ces éléments, y compris les applications informatiques, sans l'accord préalable et écrit de</p>
        <p>l'éditeur, sont strictement interdites. Le fait pour l'éditeur de ne pas engager de procédure</p>
        <p>dès la prise de connaissance de ces utilisations non autorisées ne vaut pas acceptation</p>
        <p>desdites utilisations et renonciation aux poursuites.</p>
        <p></p>
        <h3>Article 4 - Gestion du site</h3>
        <p></p>
        <p>Pour la bonne gestion du site, l'éditeur pourra à tout moment :</p>
        <p>- suspendre, interrompre ou limiter l'accès à tout ou partie du site, réserver l'accès au site,</p>
        <p>ou à certaines parties du site, à une catégorie déterminée d'internautes ;</p>
        <p>- supprimer toute information pouvant en perturber le fonctionnement ou entrant en</p>
        <p>contravention avec les lois nationales ou internationales ;</p>
        <p>- suspendre le site afin de procéder à des mises à jour.</p>
        <p></p>
        <h3>Article 5 - Responsabilités</h3>
        <p></p>
        <p>La responsabilité de l'éditeur ne peut être engagée en cas de défaillance, panne, difficulté</p>
        <p>ou interruption de fonctionnement, empêchant l'accès au site ou à une de ses</p>
        <p>fonctionnalités.</p>
        <p>Le matériel de connexion au site que vous utilisez est sous votre entière responsabilité.</p>
        <p>Vous devez prendre toutes les mesures appropriées pour protéger votre matériel et vos</p>
        <p>propres données notamment d'attaques virales par Internet. Vous êtes par ailleurs seul</p>
        <p>responsable des sites et données que vous consultez.</p>
        <p>L'éditeur ne pourra être tenu responsable en cas de poursuites judiciaires à votre encontre :</p>
        <p>- du fait de l'usage du site ou de tout service accessible via Internet ;</p>
        <p>- du fait du non-respect par vous des présentes conditions générales.</p>
        <p>L'éditeur n'est pas responsable des dommages causés à vous-même, à des tiers et/ou à</p>
        <p>votre équipement du fait de votre connexion ou de votre utilisation du site et vous renoncez</p>
        <p>à toute action contre lui de ce fait.</p>
        <p>Si l'éditeur venait à faire l'objet d'une procédure amiable ou judiciaire en raison de votre</p>
        <p>utilisation du site, il pourra se retourner contre vous pour obtenir l'indemnisation de tous</p>
        <p>les préjudices, sommes, condamnations et frais qui pourraient découler de cette procédure.</p>
        <p></p>
        <h3>Article 6 - Liens hypertextes</h3>
        <p></p>
        <p>La mise en place par les utilisateurs de tous liens hypertextes vers tout ou partie du site est</p>
        <p>strictement interdite, sauf autorisation préalable et écrite de l'éditeur.</p>
        <p>L'éditeur est libre de refuser cette autorisation sans avoir à justifier de quelque manière que</p>
        <p>ce soit sa décision. Dans le cas où l'éditeur accorderait son autorisation, celle-ci n'est dans</p>
        <p>tous les cas que temporaire et pourra être retirée à tout moment, sans obligation de</p>
        <p>justification à la charge de l'éditeur.</p>
        <p>Toute information accessible via un lien vers d'autres sites n'est pas publiée par l'éditeur.</p>
        <p>L'éditeur ne dispose d'aucun droit sur le contenu présent dans ledit lien.</p>
        <p></p>
        <h3>Article 7 - Collecte et protection des données</h3>
        <p></p>
        <p>Vos données sont collectées par l'entreprise individuelle Johann DEON.</p>
        <p>Une donnée à caractère personnel désigne toute information concernant une personne</p>
        <p>physique identifiée ou identifiable (personne concernée) ; est réputée identifiable une</p>
        <p>personne qui peut être identifiée, directement ou indirectement, notamment par référence</p>
        <p>à un nom, un numéro d'identification ou à un ou plusieurs éléments spécifiques, propres à</p>
        <p>son identité physique, physiologique, génétique, psychique, économique, culturelle ou</p>
        <p>sociale.</p>
        <p>Les informations personnelles pouvant être recueillies sur le site sont principalement</p>
        <p>utilisées par l'éditeur pour la gestion des relations avec vous, et le cas échéant pour le</p>
        <p>traitement de vos commandes.</p>
        <p>Les données personnelles collectées sont les suivantes :</p>
        <p>- nom et prénom</p>
        <p>- adresse mail</p>
        <p>- mot de passe</p>
        <p></p>
        <h3>Article 8 - Droit d’accès, de rectification et de déréférencement de vos données</h3>
        <p></p>
        <p>En application de la réglementation applicable aux données à caractère personnel, les</p>
        <p>utilisateurs disposent des droits suivants :</p>
        <p>• le droit d’accès : ils peuvent exercer leur droit d'accès, pour connaître les données</p>
        <p>personnelles les concernant, en écrivant à l'adresse électronique ci-dessous mentionnée.</p>
        <p>Dans ce cas, avant la mise en oeuvre de ce droit, la Plateforme peut demander une preuve</p>
        <p>de l'identité de l'utilisateur afin d'en vérifier l'exactitude ;</p>
        <p>• le droit de rectification : si les données à caractère personnel détenues par la Plateforme</p>
        <p>sont inexactes, ils peuvent demander la mise à jour des informations ;</p>
        <p>• le droit de suppression des données : les utilisateurs peuvent demander la suppression de</p>
        <p>leurs données à caractère personnel, conformément aux lois applicables en matière de</p>
        <p>protection des données ;</p>
        <p>• le droit à la limitation du traitement : les utilisateurs peuvent de demander à la Plateforme</p>
        <p>de limiter le traitement des données personnelles conformément aux hypothèses prévues</p>
        <p>par le RGPD ;</p>
        <p>• le droit de s’opposer au traitement des données : les utilisateurs peuvent s’opposer à ce</p>
        <p>que leurs données soient traitées conformément aux hypothèses prévues par le RGPD ;</p>
        <p>• le droit à la portabilité : ils peuvent réclamer que la Plateforme leur remette les données</p>
        <p>personnelles qu'ils ont fournies pour les transmettre à une nouvelle Plateforme.</p>
        <p>Vous pouvez exercer ce droit en nous contactant, à l’adresse suivante :</p>
        <p>91 avenue Sadi Carnot 26000 Valence.</p>
        <p>Ou par email, à l’adresse :</p>
        <p>contact@deonstudio.fr</p>
        <p>Toute demande doit être accompagnée de la photocopie d’un titre d’identité en cours de</p>
        <p>validité signé et faire mention de l’adresse à laquelle l'éditeur pourra contacter le</p>
        <p>demandeur. La réponse sera adressée dans le mois suivant la réception de la demande.</p>
        <p>Ce délai d'un mois peut être prolongé de deux mois si la complexité de la demande et/ou le</p>
        <p>nombre de demandes l'exigent.</p>
        <p>De plus, et depuis la loi n°2016-1321 du 7 octobre 2016, les personnes qui le souhaitent, ont</p>
        <p>la possibilité d’organiser le sort de leurs données après leur décès. Pour plus d’information</p>
        <p>sur le sujet, vous pouvez consulter le site Internet de la CNIL : https://www.cnil.fr/.</p>
        <p>Les utilisateurs peuvent aussi introduire une réclamation auprès de la CNIL sur le site de la</p>
        <p>CNIL : https://www.cnil.fr.</p>
        <p>Nous vous recommandons de nous contacter dans un premier temps avant de déposer une</p>
        <p>réclamation auprès de la CNIL, car nous sommes à votre entière disposition pour régler</p>
        <p>votre problème.</p>
        <p></p>
        <h3>Article 9 - Utilisation des données</h3>
        <p></p>
        <p>Les données personnelles collectées auprès des utilisateurs ont pour objectif la mise à</p>
        <p>disposition des services de la Plateforme, leur amélioration et le maintien d'un</p>
        <p>environnement sécurisé. La base légale des traitements est l’exécution du contrat entre</p>
        <p>l’utilisateur et la Plateforme. Plus précisément, les utilisations sont les suivantes :</p>
        <p>- accès et utilisation de la Plateforme par l'utilisateur ;</p>
        <p>- gestion du fonctionnement et optimisation de la Plateforme ;</p>
        <p>- mise en oeuvre d'une assistance utilisateurs ;</p>
        <p>- vérification, identification et authentification des données transmises par l'utilisateur ;</p>
        <p>- personnalisation des services en affichant des publicités en fonction de l'historique de</p>
        <p>navigation de l'utilisateur, selon ses préférences ;</p>
        <p>- prévention et détection des fraudes, malwares (malicious softwares ou logiciels</p>
        <p>malveillants) et gestion des incidents de sécurité ;</p>
        <p>- gestion des éventuels litiges avec les utilisateurs ;</p>
        <p>- envoi d'informations commerciales et publicitaires, en fonction des préférences de</p>
        <p>l'utilisateur ;</p>
        <p></p>
        <h3>Article 10 - Politique de conservation des données</h3>
        <p></p>
        <p>La Plateforme conserve vos données pour la durée nécessaire pour vous fournir ses services</p>
        <p>ou son assistance.</p>
        <p>Dans la mesure raisonnablement nécessaire ou requise pour satisfaire aux obligations</p>
        <p>légales ou réglementaires, régler des litiges, empêcher les fraudes et abus ou appliquer nos</p>
        <p>modalités et conditions, nous pouvons également conserver certaines de vos informations</p>
        <p>si nécessaire, même après que vous ayez fermé votre compte ou que nous n'ayons plus</p>
        <p>besoin pour vous fournir nos services.</p>
        <p></p>
        <h3>Article 11- Partage des données personnelles avec des tiers</h3>
        <p></p>
        <p>Les données personnelles peuvent être partagées avec des sociétés tierces exclusivement</p>
        <p>dans l’Union européenne, dans les cas suivants :</p>
        <p>- lorsque l'utilisateur publie, dans les zones de commentaires libres de la Plateforme, des</p>
        <p>informations accessibles au public ;</p>
        <p>- quand l'utilisateur autorise le site web d'un tiers à accéder à ses données ;</p>
        <p>- quand la Plateforme recourt aux services de prestataires pour fournir l'assistance</p>
        <p>utilisateurs, la publicité et les services de paiement. Ces prestataires disposent d'un accès</p>
        <p>limité aux données de l'utilisateur, dans le cadre de l'exécution de ces prestations, et ont</p>
        <p>l'obligation contractuelle de les utiliser en conformité avec les dispositions de la</p>
        <p>réglementation applicable en matière protection des données à caractère personnel ;</p>
        <p>- si la loi l'exige, la Plateforme peut effectuer la transmission de données pour donner suite</p>
        <p>aux réclamations présentées contre la Plateforme et se conformer aux procédures</p>
        <p>administratives et judiciaires.</p>
        <p></p>
        <h3>Article 12 - Offres commerciales</h3>
        <p></p>
        <p>Vous êtes susceptible de recevoir des offres commerciales de l'éditeur. Si vous ne le</p>
        <p>souhaitez pas, veuillez cliquer sur le lien suivant : johann@deonstudio.fr.</p>
        <p>Vos données sont susceptibles d’être utilisées par les partenaires de l'éditeur à des fins de</p>
        <p>prospection commerciale, si vous ne le souhaitez pas, veuillez cliquer sur le lien suivant :</p>
        <p>johann@deonstudio.fr.</p>
        <p>Si, lors de la consultation du site, vous accédez à des données à caractère personnel, vous</p>
        <p>devez vous abstenir de toute collecte, de toute utilisation non autorisée et de tout acte</p>
        <p>pouvant constituer une atteinte à la vie privée ou à la réputation des personnes. L'éditeur</p>
        <p>décline toute responsabilité à cet égard.</p>
        <p>Les données sont conservées et utilisées pour une durée conforme à la législation en</p>
        <p>vigueur.</p>
        <p></p>
        <h3>Article 13 - Cookies</h3>
        <p></p>
        <p>Qu’est-ce qu’un « cookie » ?</p>
        <p>Un « Cookie » ou traceur est un fichier électronique déposé sur un terminal (ordinateur,</p>
        <p>tablette, smartphone,…) et lu par exemple lors de la consultation d'un site internet, de la</p>
        <p>lecture d'un courrier électronique, de l'installation ou de l'utilisation d'un logiciel ou d'une</p>
        <p>application mobile et ce, quel que soit le type de terminal</p>
        <p>utilisé (source : https://www.cnil.fr/fr/cookies-traceurs-que-dit-la-loi).</p>
        <p>Le site peut collecter automatiquement des informations standards. Toutes les informations</p>
        <p>collectées indirectement ne seront utilisées que pour suivre le volume, le type et la</p>
        <p>configuration du trafic utilisant ce site, pour en développer la conception et l'agencement et</p>
        <p>à d'autres fins administratives et de planification et plus généralement pour améliorer le</p>
        <p>service que nous vous offrons.</p>
        <p>Le cas échéant, des « cookies » émanant de l'éditeur du site et/ou des sociétés tiers</p>
        <p>pourront être déposés sur votre terminal, avec votre accord. Dans ce cas, lors de la</p>
        <p>première navigation sur ce site, une bannière explicative sur l’utilisation des « cookies »</p>
        <p>apparaîtra. Avant de poursuivre la navigation, le client et/ou le prospect devra accepter ou</p>
        <p>refuser l’utilisation desdits « cookies ». Le consentement donné sera valable pour une</p>
        <p>période de treize (13) mois. L'utilisateur a la possibilité de désactiver les cookies à tout</p>
        <p>moment.</p>
        <p>Les cookies suivants sont présents sur ce site :</p>
        <p>Aucun.</p>
        <p>La durée de vie de ces cookies est de treize mois.</p>
        <p></p>
        <h3>Article 14 - Photographies et représentation des produits</h3>
        <p></p>
        <p>Les photographies de produits, accompagnant leur description, ne sont pas contractuelles et</p>
        <p>n'engagent pas l'éditeur.</p>
        <p></p>
        <h3>Article 15 - Loi applicable</h3>
        <p></p>
        <p>Les présentes conditions d'utilisation du site sont régies par la loi française et soumises à la</p>
        <p>compétence des tribunaux du siège social de l'éditeur, sous réserve d'une attribution de</p>
        <p>compétence spécifique découlant d'un texte de loi ou réglementaire particulier.</p>
        <p></p>
        <h3>Article 16 - Contactez-nous</h3>
        <p></p>
        <p>Pour toute question, information sur les produits présentés sur le site, ou concernant le site</p>
        <p>lui-même, vous pouvez laisser un message à l'adresse suivante : contact@deonstudio.fr.</p>
        </div>
        <div class="scroll-top">
            <transition name="fade">
                <div id="pagetop" class="scroll-but" v-show="scY > 300" @click="toTop">
                    
                </div>
            </transition>
        </div>
    </div>
    
</template>
<script>
export default{
    data() {
      return {
        scTimer: 0,
        scY: 0,
      }
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll);
    },
    methods: {
      handleScroll: function () {
        if (this.scTimer) return;
        this.scTimer = setTimeout(() => {
          this.scY = window.scrollY;
          clearTimeout(this.scTimer);
          this.scTimer = 0;
        }, 100);
      },
      toTop: function () {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      },
    },
}
</script>

<style scoped>
/****************DESKTOP/MOBILE****************/
@media only screen and (max-width: 800px) {/*Mobile*/
a{
    font-family: Font1;
    font-size: 1rem;
    color: var(--Text-color);
    padding-top: 5%;
    padding-bottom: 5%;
    padding-left: 2%;
}
.mentions p{
    padding-bottom: .5%;
    font-family: Font2;
    padding-left: 5%;
    margin: 0;
    font-size: 10px;
}
.mentions h3{
    
    font-family: Font1;
    font-size: 1rem;
    padding: 5%;
    margin: 0;
}
.mention-container{
    padding: 5%;
}
.scroll-but{
    position: fixed;
    bottom:  0%;
    right: 0;
    width: 10%;
    height: 10%;
    background-image: url("@/assets/IMGs/top.png");
    background-repeat: no-repeat;
    background-size: contain;
}
.mentions{  
    
  background-color: var(--box-color);
  padding-top: 0%;
  height: 50%;
  width: 98vw;
  margin: auto;
  display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: stretch;
	align-content: stretch;
  padding-bottom: 1%;
  border-radius: 10px;
  margin-bottom: 1rem;
}
}
@media only screen and (min-width: 800px) {/*Desktop*/
a{
    font-family: Font1;
    font-size: 2rem;
    color: var(--Text-color);
    padding-top: 5%;
    padding-bottom: 5%;
    padding-left: 2%;
}
.mentions p{
    padding-bottom: .5%;
    font-family: Font2;
    padding-left: 5%;
    margin: 0;
}
.mentions h3{
    
    font-family: Font1;
    font-size: 2rem;
    padding: 5%;
    margin: 0;
}
.mention-container{
    padding: 5%;
}
.scroll-but{
    position: fixed;
    bottom:  0%;
    right: 0;
    width: 10%;
    height: 10%;
    background-image: url("@/assets/IMGs/top.png");
    background-repeat: no-repeat;
    background-size: contain;
}
.mentions{  
    
  background-color: var(--box-color);
  padding-top: 0%;
  height: 50%;
  width: 98vw;
  margin: auto;
  display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: stretch;
	align-content: stretch;
  padding-bottom: 1%;
  border-radius: 10px;
  margin-bottom: 1rem;
}
}

/*********************Fonts*********************/


</style>