<template>
  <div class="Reverse-Chord">

    <!-- Recherche d'accords inversés-->
    
    <div class="piano-app">

      <h1>Search for inverted chords</h1>
      <p>This tool allows you to find the reversed chords</p>
      <div class="switch-note">
        <h1>Note names:</h1>
      <div
        @click="toggleNoteDisplayType"
        :class="{'change-notes': !isClicked, 'clicked': isClicked}"
      >
        
      </div>
      </div>
      <div class="piano">
        <!-- Octave 1 -->
        <div
          v-for="note in octave(1)"
          :key="note"
          :class="{ 
            'white-key': isWhiteKey(note), 
            'black-key': !isWhiteKey(note), 
            'selected-key': isSelected(note) 
          }"
          @click="toggleNoteSelection(note)"
          :data-note="note"
        >
          {{ getNoteDisplayName(note) }}
        </div>
      </div>
      <h2 v-if="selectedNotes.length === 3">Selected chord: {{ getChordName() }}</h2>
      <div class="play-chords" v-if="selectedNotes.length === 3" @click="playChord"><p1>Play</p1></div>

      
    </div>


    <!-- Recherche d'accords inversés-->
    <!-- others projects -->
      
    <!-- others projects -->
    
    
    
  </div>
</template>

<script>
// import * as THREE from 'three';

// const scene = new THREE.Scene();
// const camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 0.1, 1000 );

// const renderer = new THREE.WebGLRenderer();
// renderer.setSize( window.innerWidth, window.innerHeight );
// document.body.appendChild( renderer.domElement );

export default {
  data() {
    return {
      
      selectedNotes: [],
      noteDisplayType: "C, C#, D, D#, ...", // Par défaut
      isClicked: false, // Ajout de la variable d'état
      

       
    };
  },
  


  methods: {
    
  
    

    getPositionX(note) {
      // Définissez la position horizontale de chaque note sur le piano
      const positionMap = {
        'C': 1,
        'C#': 2,
        'D': 3,
        'D#': 4,
        'E': 5,
        'F': 6,
        'F#': 7,
        'G': 8,
        'G#': 9,
        'A': 10,
        'A#': 11,
        'B': 12,
        'C2': 13,
        'C#2': 14,
        'D2': 15,
        'D#2': 16,
        'E2': 17,
        'F2': 18,
        'F#2': 19,
        'G2': 20,
        'G#2': 21,
        'A2': 22,
        'A#2': 23,
        'B2': 24,
      };
      return positionMap[note];
    },

    isWhiteKey(note) {
      return !note.includes('#');
      
    },
    octave(/*octaveNumber*/) {
      const notes = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B','C2', 'C#2', 'D2', 'D#2', 'E2', 'F2', 'F#2', 'G2', 'G#2', 'A2', 'A#2', 'B2'];
      return notes.map(note => note /*+ octaveNumber*/);
    },
    isSelected(note) {
      return this.selectedNotes.includes(note);
    },
    toggleNoteSelection(note) {
      // Toggle la sélection d'une note
      if (this.selectedNotes.includes(note)) {
        // Si la note est déjà sélectionnée, la retirer
        const index = this.selectedNotes.indexOf(note);
        if (index > -1) {
          this.selectedNotes.splice(index, 1);
        }
      } else if (this.selectedNotes.length < 3) {
        // Sinon, l'ajouter aux notes sélectionnées (maximum 3 notes)
        this.selectedNotes.push(note);
      }
    },
    getChordName() {
      // Logique pour afficher le nom de l'accord en fonction des notes sélectionnées
      const chord = this.detectChord();
      //const affichageToggle = this.toggleNoteDisplayType();
      

      if (chord) 
      {
        
        const fundamentalNote = this.getFundamentalNote(chord);
        const chordName = this.getChordNameFromNotes(chord);
        //if(!affichageToggle){
          
          return `${fundamentalNote} ${chordName}`;
        /*}else if(affichageToggle) 
        {
  
          return `${chordName} ${fundamentalNote}`;
        }*/
      } 
      else
      {
        return 'Unknown chord';
      }
    },
    detectChord() {
      // Logique pour détecter l'accord en fonction des notes sélectionnées
      if (this.selectedNotes.length !== 3) {
        return null; // Nous ne traitons que les accords à trois notes pour cet exemple
      }

      // Obtenez les indices des notes sélectionnées dans la liste des notes
      const noteIndices = this.selectedNotes.map(note => {
        return this.octave(1).indexOf(note);
      });

      // Triez les indices pour garantir l'ordre croissant
      noteIndices.sort((a, b) => a - b);

      // Calculez les intervalles entre les indices des notes
      const intervals = [
        noteIndices[1] - noteIndices[0],
        noteIndices[2] - noteIndices[1]
      ];

      // Modèles d'accords pour accord majeur, mineur et diminué
      const chordMappings = {
        "4-3": "major",
        "3-4": "minor",
        "3-3": "diminished"
      };

      // Formatez les intervalles en chaîne de caractères et vérifiez s'ils correspondent à un modèle d'accord
      const intervalString = intervals.join("-");
      return chordMappings[intervalString] || null;
    },
    getFundamentalNote() {
      const chord = this.detectChord();
      if (chord) {
        // Triez les notes sélectionnées par leur position horizontale sur le piano
        const sortedNotes = this.selectedNotes.slice().sort((a, b) => {
          const positionA = this.getPositionX(a);
          const positionB = this.getPositionX(b);
          return positionA - positionB;
        });

        // La note fondamentale est la première note triée (la plus à gauche sur l'affichage du piano)
        return sortedNotes[0];
      } else {
        return 'Unknown chord';
      }
    },
    getChordNameFromNotes(chord) {
      // Associe les accords détectés aux noms d'accords
      const chordNames = {
        major: 'Majeur',
        minor: 'Mineur',
        diminished: 'Diminué',
      };
      return chordNames[chord];
    },
    playChord() {
      // Jouez l'accord ici en utilisant le nom de fichier basé sur l'accord sélectionné
      const chordFileName = this.getChordFileName();
      const audio = new Audio(require(`@/assets/chords/${chordFileName}.mp3`));
      audio.play();
    },
    getChordFileName() {
      // Retourne le nom du fichier audio basé sur l'accord sélectionné
      const chordName = this.getChordName();
      const sanitizedChordName = chordName.replace(/ /g, '_'); // Remplace les espaces par des underscores
      const fullnameChord = sanitizedChordName.replace(/#/g, 'b');
      return `${fullnameChord}`;
    },


    ////
    toggleNoteDisplayType() {
      // Basculer entre les affichages des notes
      this.noteDisplayType =
        this.noteDisplayType === "C, C#, D, D#, ..."
          ? "Do, Do#, Ré, Ré#, ..."
          : "C, C#, D, D#, ...";
      // Basculez l'état "isClicked" lorsque l'utilisateur clique sur la div
      this.isClicked = !this.isClicked;
      return this.isClicked;
    },
    /////


    getNoteDisplayName(note) {
      // Convertir les noms des notes en fonction de l'affichage choisi par l'utilisateur
      if (this.noteDisplayType === "Do, Do#, Ré, Ré#, ...") {
        const noteMap = {
          "C": "Do",
          "C#": "Do#",
          "D": "Ré",
          "D#": "Ré#",
          "E": "Mi",
          "F": "Fa",
          "F#": "Fa#",
          "G": "Sol",
          "G#": "Sol#",
          "A": "La",
          "A#": "La#",
          "B": "Si",
          "C2": "Do2",
          "C#2": "Do#2",
          "D2": "Ré2",
          "D#2": "Ré#2",
          "E2": "Mi2",
          "F2": "Fa2",
          "F#2": "Fa#2",
          "G2": "Sol2",
          "G#2": "Sol#2",
          "A2": "La2",
          "A#2": "La#2",
          "B2": "Si2"
        };
        return noteMap[note];
      } else {
        return note;
      }
    },
  },
};
</script>
<style scoped>
/****************DESKTOP/MOBILE****************/
@media only screen and (max-width: 800px) {/*Mobile*/
h2{
    font-size: 20px;
    text-align: center;
    padding: 5%;
}
a{
  color: white;
}
p{
    text-align: center;
    font-family: font1;
}
}
@media only screen and (min-width: 800px) {/*Desktop*/
h2{
    text-align: center;
    padding: 5%;
}
a{
  color: white;
}
p{
    text-align: center;
    font-family: font1;
}
}

/*********************Fonts*********************/

.bar-separate{
  margin-top: 10%;
  border-radius: 10em;
  width: 90%;
  height: 3vw;
  
  border: .2vh solid rgba(255, 255, 255, 1);
}
.support-us{
  width: 100%;
  display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: space-around;
	align-items: center;
	align-content: stretch;
  margin-top: 10%;
  margin-bottom: 10%;
}
.Tools-about a{
  font-size: 3vw;
  padding-bottom: 5%;
}
.Tools-about p{
  text-align: left;
  font-size: 1.5vw;
  padding-top: 0;
  margin: 0;
}
.Tools-about{
  width: 100%;
  display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: space-around;
	align-items: center;
	align-content: stretch;
}


@keyframes time-bar-scrolling {
    0% {
        left: 0;
        
    }
    100% {
        left: 98%;
        
    }
}




.switch-note h1{
  font-size: 1.5vw;
  color: #ffffff;
}
.play-chords{
  width: 8vw;
  height: 5vh;
  border-radius: 20rem;
  border: .2rem solid var(--Text-color);
  color: var(--Text-color);
  font-family: Font1;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  cursor: pointer;
}
.play-chords:hover{
  filter: brightness(50%);
}
.play-chords p1{

  margin: 1%;
}
.switch-note{
  position: relative;
  margin: 0;
  width: 100%;
  height: 10%;
  padding-bottom: 3%;
  display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: stretch;
	align-content: center;
}
.clicked {
  border-radius: 10rem;
  background: #cecece;
  
  margin: auto;
  width: 5rem;
  height: 2rem;
  background-image: url("../assets/chords/Do.png");
  background-position: center;
  background-size: contain ;
  background-repeat: no-repeat;
  text-decoration: none;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  cursor: pointer;
}

.change-notes{
  
  background: #ffffff;
  border-radius: 5vh;
  
  margin: auto;
  width: 5rem;
  height: 2rem;

  background-image: url("../assets/chords/C.png");
  background-position: center;
  background-size: contain ;
  background-repeat: no-repeat;
  text-decoration: none;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  cursor: pointer;
}
.p3{
  text-align: center;
  font-size: 1.5vw;
  font-family: Font1;
  padding: 10%;
  color: var(--grey);
   text-decoration: none;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  cursor: pointer;
}
h3{
    padding-left: 10%;
    font-size: 2vw;
    font-family: Font1;
    color: #fff;
}
.Reverse-Chord {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
 
  

}
p {
  color: #fff;
  padding: 1%;
}
h1 {
  text-align: center;
  font-size: 2vw;
  font-family: Font1;
  color: #fff;
  text-decoration: none;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.piano-app {
  background-color: var(--box-color);
  padding-top: 0%;
  height: 50%;
  width: 98vw;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  padding-bottom: 1%;
  border-radius: 10px;
  margin-bottom: 1rem;
    
}
.piano {
  display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: stretch;
	align-content: stretch;
  width: 100%;
  


}
.white-key {
  border-radius: 0 0 0.5rem 0.5rem;
  width: 4.5vw;
  height: 10em;
  background-color: white;
  border: .1rem solid #c0c0c0;
  cursor: pointer;
  margin-bottom: 1rem; /* Espace sous les touches blanches */
  margin-left: 0vw;
  margin-right: 0vw;

  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-size: 2vw;
  font-family: Font1;
  color: #000000;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.black-key {
  border-radius: 0 0 0.5rem 0.5rem;
  width: 4vw; /* Ajustez la largeur des touches noires */
  height: 6em; /* Ajustez la hauteur des touches noires */
  background-color: black;
  position: relative;
  
  margin-right: -2vw; 
  margin-left: -2vw;
  
  z-index: 1;
  cursor: pointer;
  bottom: 0;
  
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-size: 1.5vw;
  font-family: Font1;
  color: #ffffff;
  text-decoration: none;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.selected-key {
  filter: brightness(80%);/* Couleur de fond pour les touches sélectionnées */
  background-color: #cecece ;
}
</style>
